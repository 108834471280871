import React, { useEffect, useState } from 'react';

// - react-bootstrap
import { Container /* , Col, Row, */ } from 'react-bootstrap';

import PageHeader from 'components/PageHeader';

// - images
// import joaoTomasini from 'assets/img/directors/joao-tomasini.jpg';
// import charlesYatman from 'assets/img/directors/charles-yatman.jpg';
// import ceciliaFarias from 'assets/img/directors/cecilia_2.jpg';
// import dwightCorbin from 'assets/img/directors/dwight.jpg';

// - styles
import {
  TeamHolder,
  ImageHolder,
  Name,
  BoardOfDirectorsContainer,
} from './styles';

import Cover from 'assets/img/boardofdirectors.png';

const origin = process.env.REACT_APP_BASE_URL;

function BoardOfDirectors() {
  const [directors, setDirectors] = useState([]);

  useEffect(() => {
    fetch(`${origin}/api/directors`)
      .then(async (response) => {
        const json = await response.json();
        setDirectors(json);
        console.log(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []);

  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'About Us',
      path: '/about',
    },
    {
      title: 'Board Of Directors',
    },
  ];

  return (
    <>
      <PageHeader
        bannerTitle="boardOfDirectors"
        pageTitle="boardOfDirectors"
        breadcrumbs={breadcrumbs}
        img={Cover}
      />
      <Container>
        <div className="my-4 py-4">
          <BoardOfDirectorsContainer>
            {directors.map((director) => (
              <TeamHolder key={director._id}>
                <ImageHolder>
                  <img
                    src={`${origin}${director.imagePath}`}
                    alt="João Tomasini Schwertner"
                    className="img-responsive"
                  />
                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">{director.name}</h5>
                <Name>{director.position.english}</Name>
              </TeamHolder>
            ))}
          </BoardOfDirectorsContainer>
        </div>
      </Container>
    </>
  );

  /* return (
    <>
      <PageHeader bannerTitle="Board Of Directors" pageTitle="Board Of Directors" breadcrumbs={breadcrumbs} />
      <Container>
        <div className="my-4 py-4 d-inline-block">
          <Row>
            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <img src={joaoTomasini} alt="João Tomasini Schwertner" className="img-responsive" />
                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">João Tomasini Schwertner (BRA)</h5>
                <Name>President</Name>
              </TeamHolder>
            </Col>

            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <div className="hover-box">
                    <p className="text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum.</p>
                  </div>
                  <img src={charlesYatman} alt="Charles Yatman" className="img-responsive" />

                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">Charles Yatman (USA)</h5>
                <Name>Honorary President</Name>

              </TeamHolder>
            </Col>

            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <div className="hover-box">
                    <p className="text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum.</p>
                  </div>
                  <img src={ceciliaFarias} alt="Cecilia Farias" className="img-responsive" />

                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">Cecilia Farias (ARG)</h5>
                <Name>Honorary President</Name>

              </TeamHolder>
            </Col>

            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <div className="hover-box">
                    <p className="text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum.</p>
                  </div>
                  <img src={dwightCorbin} alt="Dwight Corbin" className="img-responsive" />

                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">Dwight Corbin (CAN)</h5>
                <Name>1st VP</Name>

              </TeamHolder>
            </Col>

          </Row>
          <Row>
            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <div className="hover-box">
                    <p className="text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum.</p>
                  </div>
                  <img src="http://placehold.it/400x450" alt="Sergio Rafael Navarro Rovero" className="img-responsive" />

                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">Sergio Rafael Navarro Rovero (VEN)</h5>
                <Name>2nd VP</Name>

              </TeamHolder>
            </Col>

            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <div className="hover-box">
                    <p className="text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum.</p>
                  </div>
                  <img src="http://placehold.it/400x450" alt="Christian Moya León" className="img-responsive" />

                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">Christian Moya León (CHI)</h5>
                <Name>General Secretary</Name>

              </TeamHolder>
            </Col>

            <Col lg={3} md={6} className="py-4">
              <TeamHolder>
                <ImageHolder>
                  <div className="hover-box">
                    <p className="text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent mattis aliquet purus non elementum.</p>
                  </div>
                  <img src="http://placehold.it/400x450" alt="Othón H. Diaz Valenzuela" className="img-responsive" />

                </ImageHolder>
                <div className="clearfix" />
                <br />
                <h5 className="mb-1">Othón H. Diaz Valenzuela (MEX)</h5>
                <Name>Treasurer</Name>

              </TeamHolder>
            </Col>

          </Row>
        </div>
      </Container>
    </>
  ); */
}

export default BoardOfDirectors;
