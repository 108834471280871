import styled from 'styled-components';

export const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  padding: 0px;
  margin: 0px;
  float: left;
  z-index: 0;
  overflow: hidden;
  height: 248px;

  @media only screen and (max-width: 1169px) and (min-width: 1000px) {
    height: 160px;
  }

  @media only screen and (max-width: 767px) and (min-width: 640px) {
    height: 200px;
  }

  @media only screen and (max-width: 639px) and (min-width: 480px) {
    height: 150px;
  }

  & .banner-text {
    position: absolute;
    width: 100%;
    padding: 0;
    margin: 0px;
    float: left;
    z-index: 3;
    text-transform:uppercase;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    & .title {
      margin: 0px;
      font-size: 30px;
      font-weight: 700;
    }

    & .subtitle {
      font-size: 18px;
      line-height: 23px;
      margin-top: 10px;
      margin-bottom: 16px;
      font-style: normal;
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  float: left;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const TitleBar = styled.div`
  border-bottom: 1px solid #e9e9e9;
  float: left;
  margin: 0;
  padding-top: 15px;
  width: 100%;

  & .breadcrumb {
    background: transparent;

    & .breadcrumb-item a {
      color: #161616;
    }
  }
`;
