import React, { useState, useEffect, useContext } from 'react';

// -- styled-components do Footer
import {
  FooterTitle,
  TitleUnderline,
  NewsImage,
  NewsText,
  NewsTitle,
  NewsPostInfo,
  /* DividerLine, */
} from '../styles';

// - tradução
import { LanguageContext } from 'containers/Language';

const origin = process.env.REACT_APP_BASE_URL;

export default function RecentNews({ Text }) {
  const [recentNews, setRecentNews] = useState([]);

  const { userLanguage } = useContext(LanguageContext);

  useEffect(() => {
    fetch(`${origin}/api/news?page=1&perPage=2`)
      .then(async (response) => {
        const json = await response.json();
        setRecentNews(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []);

  return (
    <>
      <FooterTitle>
        <Text content="recentNews" />
      </FooterTitle>
      <TitleUnderline />
      <div className="clearfix" />

      {recentNews.map((newsItem) => (
        <div className="news" key={newsItem._id} style={{ paddingBottom: 32 }}>
          <NewsImage>
            {/* <img src="http://placehold.it/80x80" alt="Notícia" /> */}
            <img src={`${origin}${newsItem.imagePath}`} alt="Notícia" />
          </NewsImage>
          <NewsText>
            <NewsTitle>
              <a href={`/news/${newsItem._id}`}>
                {userLanguage === 'es'
                  ? newsItem.spanish.title
                  : newsItem.english.title}
              </a>
            </NewsTitle>
            <p>
              {userLanguage === 'es'
                ? `${newsItem.spanish.subheadline.slice(50)}...`
                : `${newsItem.english.subheadline.slice(50)}...`}
            </p>
            <NewsPostInfo>
              <span>{newsItem.author}</span>
              <br />
              <span>
                {new Date(newsItem.createdAt).toLocaleDateString(userLanguage, {
                  day: 'numeric',
                  month: 'long',
                })}
              </span>
            </NewsPostInfo>
          </NewsText>
        </div>
      ))}

      {/* <div className="news">
        <NewsImage>
          <img src="http://placehold.it/80x80" alt="Notícia" />
        </NewsImage>
        <NewsText>
          <NewsTitle>
            <a href="#noticia">Lorem ipsum</a>
          </NewsTitle>
          <p>Lorem ipsum dolor sit</p>
          <NewsPostInfo>
            <span>By John Doe</span>
            <span>May 19</span>
          </NewsPostInfo>
        </NewsText>
      </div>
      <DividerLine />
      <div className="news">
        <NewsImage>
          <img src="http://placehold.it/80x80" alt="Notícia" />
        </NewsImage>
        <NewsText>
          <NewsTitle>
            <a href="#noticia">Lorem ipsum</a>
          </NewsTitle>
          <p>Lorem ipsum dolor sit</p>
          <NewsPostInfo>
            <span>By John Doe</span>
            <span>May 19</span>
          </NewsPostInfo>
        </NewsText>
      </div> */}
    </>
  );
}
