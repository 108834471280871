import React, { useState, useEffect, useRef } from 'react';

// - react-bootstrap
import {
  Col, Row, Table, Pagination,
} from 'react-bootstrap';

// - data
import federations from 'assets/federations';

// - Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';

// - styles
import {
  Form, Input, Select, Arrow, Button,
} from '../styles/federationsTableStyles';

function FederationsTable() {
  const pageSize = 50;

  // const [filter, setFilter] = useState('All');
  const [page, setPage] = useState(1);
  const [federationsList, setFederationsList] = useState([]);

  const tableRef = useRef(null);
  const executeScroll = () => window.scrollTo({ behavior: 'smooth', top: tableRef.current.offsetTop });

  useEffect(() => {
    setFederationsList(federations.slice(0, pageSize));
    // setFilter('All');
  }, []);

  useEffect(() => {
    console.log(page);
    const start = (page - 1) * pageSize;
    const finish = page * pageSize;

    setFederationsList(federations.slice(start, finish));
  }, [page]);

  // useEffect(() => {
  //   setFederationsList([]);

  //   const filtered = federationsList.map((n) => ({ ...n, filtered: n.category.includes(filter) }));

  //   setFederationsList(filtered);
  // }, [filter]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-around">
          <Col lg="4" md="6" sm="12" style={{ position: 'relative' }}>
            <label htmlFor="country">Country</label>
            <Select id="country" className="d-block">
              {federations.map((federation) => (
                <option key={federation.ioc} value={federation.country}>{federation.country}</option>
              ))}
            </Select>
            <Arrow>
              <FontAwesomeIcon
                icon={faCaretDown}
                size="lg"
                color="white"
              />
            </Arrow>

          </Col>
          <Col lg="3" md="6" sm="12">
            <label htmlFor="name">Name</label>
            <Input id="name" type="text" placeholder="Type here the name" className="d-block" />
          </Col>
          <Col lg="3" md="6" sm="12">
            <label htmlFor="ioc">IOC Code</label>
            <Input id="ioc" type="text" placeholder="Type here the code" className="d-block" />
          </Col>
          <Col lg="2" md="6" sm="12" className="d-flex align-items-end justify-content-end">
            <Button type="submit">
              Search
              <FontAwesomeIcon
                icon={faSearch}
                size="lg"
                color="white"
                style={{ marginLeft: 10 }}
              />
            </Button>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col>
          <Table striped bordered hover ref={tableRef}>
            <thead>
              <tr>
                <th>Flag</th>
                <th>IOC Code</th>
                <th>Country</th>
                <th>National Federation</th>
              </tr>
            </thead>
            <tbody>
              {federationsList.map((federation) => (
                <tr key={federation.ioc}>
                  <td />
                  <td>{federation.ioc}</td>
                  <td>{federation.country}</td>
                  <td>{federation.nationalFederation}</td>
                </tr>
              ))}

            </tbody>
          </Table>

        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center" style={{ padding: '20px 0 80px' }}>
          <Pagination>
            <Pagination.First onClick={() => { setPage(1); executeScroll(); }} />
            <Pagination.Prev disabled={page === 1} onClick={() => { setPage(page - 1); executeScroll(); }} />
            {[...Array(4)].map((_, i) => (
              <Pagination.Item active={page === (i + 1)} onClick={() => { setPage(i + 1); executeScroll(); }}>{i + 1}</Pagination.Item>
            ))}
            <Pagination.Next disabled={page === 4} onClick={() => { setPage(page + 1); executeScroll(); }} />
            <Pagination.Last onClick={() => { setPage(4); executeScroll(); }} />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

export default FederationsTable;
