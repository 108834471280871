import styled from 'styled-components';

import { colors } from 'assets/styles/colors';

export const NewsTitle = styled.h5`
  margin-bottom: 3px;
  text-transform: uppercase;
`;

export const ImageHolder = styled.div`
  margin-bottom: 37px;
  position: relative;

  & .caption {
    & h4 {
      color: #fff;
      margin: 20px 0;
    }

    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 100px;
  }
`;

export const Divider = styled.div`
  margin: 20px 0;
  border-bottom: 1px solid;
`;

export const NewsButton = styled.a`
  color: #fff;
  background-color: ${colors.yellow};
  border-radius: 2px;
  padding: 10px 36px;
  box-shadow: none;
  align-self: flex-start;
  /* align-self: center; */

  &:hover {
    color: #fff;
    background-color: #242424;
  }
`;

export const FilterButton = styled.button`
  margin-right:15px;
  margin-bottom: 8px;
  border: none;
  padding: 5px 15px;
  color: #fff;
  background-color: ${(props) => (props.active ? colors.yellow : '#aaa')};

  &:focus {
    outline: 0;
  }
`;
