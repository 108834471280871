import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

// - react-bootstrap
import { Container, Col, Row, Table } from 'react-bootstrap';

// - styled-components
// import eventImage from 'assets/img/news/Rio-2020-Pan-American-Master-Games-LOGO2.jpeg';
// import { PublishDate, ImageHolder } from '../styles/eventsStyles';
import { Section, Title, Underline } from '../homeStyles';

// - Imagens

// - tradução
import { LanguageContext } from '../../../containers/Language';

const origin = process.env.REACT_APP_BASE_URL;

function Events({ Text }) {
  const [events, setEvents] = useState([]);

  const { userLanguage } = useContext(LanguageContext);

  useEffect(() => {
    const now = new Date();
    const currentYear = now.getFullYear();
    fetch(`${origin}/api/events?year=${currentYear}`)
      .then(async (response) => {
        const json = await response.json();
        setEvents(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []);

  return (
    <Section>
      <Container>
        <Row>
          <Col>
            <Title>{userLanguage === 'es' ? 'Eventos' : 'Events'}</Title>
            <Underline />
            {events.length !== 0 ? (
              <Table striped bordered hover /* ref={tableRef} */>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Event</th>
                    <th>Place</th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((event) => {
                    const options =
                      userLanguage === 'en'
                        ? {
                            year: 'numeric',
                            month: 'long',
                          }
                        : {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          };

                    let startDate = new Date(event.startDate);
                    let endDate = new Date(event.endDate);

                    let date;

                    if (startDate.getMonth() === endDate.getMonth()) {
                      if (userLanguage === 'es') {
                        date = `${startDate.getDate()} al ${endDate.toLocaleDateString(
                          userLanguage,
                          options
                        )}`;
                      } else {
                        date = `${startDate.getDate()}th to ${endDate.getDate()}th ${endDate.toLocaleDateString(
                          userLanguage,
                          { year: 'numeric', month: 'long' }
                        )}`;
                      }
                    } else {
                      if (userLanguage === 'es') {
                        date = `${startDate.toLocaleDateString(
                          userLanguage,
                          options
                        )} al ${endDate.toLocaleDateString(
                          userLanguage,
                          options
                        )}`;
                      } else {
                        date = `${startDate.getDate()}th ${startDate.toLocaleDateString(
                          userLanguage,
                          options
                        )} to ${endDate.getDate()}th ${endDate.toLocaleDateString(
                          userLanguage,
                          options
                        )}`;
                      }
                    }

                    return (
                      <tr key={event._id}>
                        <td>{date}</td>
                        <td>
                          <Link to={`/events/${event._id}`}>
                            {userLanguage === 'es'
                              ? event.spanish.title
                              : event.english.title}
                          </Link>
                        </td>
                        <td>{event.local}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div
                className="alert alert-warning text-center"
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  margin: '32px  0 0',
                  width: '100%',
                }}
              >
                <Text content="noRecordsFound" />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Section>
  );

  /* return (
    <Section>
      <Container>
        <Row>
          {[...Array(3)].map((e, i) => (
            <Col md="6" lg="4" key={i}>
              <Title>
                <Text content="events" />
              </Title>
              <Underline />
              <div>
                <ImageHolder>
                  <PublishDate>
                    {' '}
                    05
                    <span>
                      <Text content="september" />
                      , 2020
                    </span>
                  </PublishDate>
                  <img src={eventImage} className="img-responsive" alt="Event" />
                </ImageHolder>
                <div className="clearfix" />
                <a href="#event">
                  <h5>Rio 2020 Pan American Master Games *postponed*</h5>
                </a>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent
                  mattis commodo augue.
                  <br />
                  Aliquam ornare hendrerit augue.
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  ); */
}

export default Events;
