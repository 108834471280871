import styled from 'styled-components';

import { colors } from 'assets/styles/colors';

export const Section = styled.div`
  padding: 80px 0;
`;

export const Title = styled.h4`
  text-transform: uppercase;
`;

export const Underline = styled.div`
  background-color: ${colors.yellow};
  width: 7%;
  float: left;
  margin: 0 93% 37px 0;
  height: 3px;
  padding: 0px;
`;
