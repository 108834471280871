import React, { useContext, useEffect } from 'react';

import { languageOptions } from 'assets/languages';
import { LanguageContext } from 'containers/Language';

// - styled-components

// - Imagens
import flagUS from 'assets/img/flag-us.png';
import flagSpain from 'assets/img/flag-spain.png';
import { Button } from './styles';

export default function LanguageSelector() {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  // Seleciona a linguagem chamando o método do Context
  const handleLanguageChange = (value) => userLanguageChange(value);

  useEffect(() => {
    let defaultLanguage = window.localStorage.getItem('rcml-lang');
    if (!defaultLanguage) {
      defaultLanguage = window.navigator.language.substring(0, 2);
    }
    userLanguageChange(defaultLanguage);
  }, [userLanguageChange]);

  return (
    <div>
      {Object.entries(languageOptions).map(([id, name]) => (
        <Button key={id} onClick={() => handleLanguageChange(id)} value={userLanguage}>
          <img src={id === 'en' ? flagUS : flagSpain} width="16" alt={id} />
          {' '}
          {id}
        </Button>
      ))}
    </div>
  );
}
