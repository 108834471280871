import styled from 'styled-components';

import { colors } from 'assets/styles/colors';
import openQuote from 'assets/img/openquote1.png';
import closeQuote from 'assets/img/closequote1.png';

export const TextBox = styled.div`
  margin-bottom: 80px;
  padding: 40px;
  background-color: ${colors.yellow};
`;

export const BlockQuote = styled.blockquote`
  padding: 8px;
  margin: 5px;
  font-size: 14px;
  border-left: none;
  background-image: url(${openQuote});
  background-position: top left;
  background-repeat: no-repeat;
  text-indent: 23px;

  & span {
    display: block;
    background-image: url(${closeQuote});
    background-repeat: no-repeat;
    background-position: bottom right;

    & h5 {
      text-transform: uppercase;
      color: #fff;
    }
  }
`;
