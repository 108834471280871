const federations = [
  {
    ioc: 'AFG',
    country: 'AFGHANISTAN',
    nationalFederation: 'AFGHANISTAN CANOEING FEDERATION',
  }, {
    ioc: 'ALB',
    country: 'ALBANIA',
    nationalFederation: 'ALBANIAN ROWING AND CANOE ASSOCIATION "LIBURNA"',
  }, {
    ioc: 'ALG',
    country: 'ALGERIA',
    nationalFederation: "FÉDÉRATION ALGÉRIENNE DES SOCIÉTÉS D'AVIRON ET DE CANOE / KAYAK",
  }, {
    ioc: 'ASA',
    country: 'AMERICAN SAMOA',
    nationalFederation: 'AMERICAN SAMOA ASSOCIATION OF PADDLERS',
  }, {
    ioc: 'AND',
    country: 'ANDORRA',
    nationalFederation: 'FEDERACIÓ ANDORRANA DE CANOA I CAIAC',
  }, {
    ioc: 'ANG',
    country: 'ANGOLA',
    nationalFederation: 'FEDERAÇÃO ANGOLANA DE DESPORTO NAUTICO',
  }, {
    ioc: 'ANT',
    country: 'ANTIGUA (WEST INDIES)',
    nationalFederation: 'ANTIGUA & BARBUDA CANOE AND KAYAK ASSOCIATION',
  }, {
    ioc: 'ARG',
    country: 'ARGENTINA',
    nationalFederation: 'FEDERACIÓN ARGENTINA DE CANOAS',
  }, {
    ioc: 'ARM',
    country: 'ARMENIA',
    nationalFederation: 'ARMENIAN NATIONAL CANOE FEDERATION',
  }, {
    ioc: 'ARU',
    country: 'ARUBA - DUTCH CARIBBEAN',
    nationalFederation: 'ARUBA ROWING AND CANOE ASSOCIATION',
  }, {
    ioc: 'AUS',
    country: 'AUSTRALIA',
    nationalFederation: 'PADDLE AUSTRALIA',
  }, {
    ioc: 'AUT',
    country: 'AUSTRIA',
    nationalFederation: 'OESTERREICHISCHER KANUVERBAND',
  }, {
    ioc: 'AZE',
    country: 'AZERBAIJAN',
    nationalFederation: 'AZERBAIJAN ROWING & CANOE FEDERATION ',
  }, {
    ioc: 'BAR',
    country: 'BARBADOS',
    nationalFederation: 'BARBADOS KAYAK ASSOCIATION',
  }, {
    ioc: 'BLR',
    country: 'BELARUS',
    nationalFederation: 'BELARUS CANOE ASSOCIATION',
  }, {
    ioc: 'BEL',
    country: 'BELGIUM',
    nationalFederation: 'ROYAL BELGIAN CANOE FEDERATION',
  }, {
    ioc: 'BIZ',
    country: 'BELIZE',
    nationalFederation: 'BELIZE CANOE ASSOCIATION',
  }, {
    ioc: 'BHU',
    country: 'BHUTAN',
    nationalFederation: 'BHUTAN CANOE FEDERATION',
  }, {
    ioc: 'BOL',
    country: 'BOLIVIA',
    nationalFederation: "FEDERACIÓN BOLIVIANA DE CANOTAJE 'FEBOCA'",
  }, {
    ioc: 'BIH',
    country: 'BOSNIA AND HERZEGOVINA',
    nationalFederation: 'CANOE FEDERATION OF BOSNIA AND HERZEGOVINA',
  }, {
    ioc: 'BOT',
    country: 'BOTSWANA',
    nationalFederation: 'BOTSWANA ROWING AND CANOEING FEDERATION',
  }, {
    ioc: 'BRA',
    country: 'BRAZIL',
    nationalFederation: 'BRAZILIAN CANOE CONFEDERATION',
  }, {
    ioc: 'IVB',
    country: 'BRITISH VIRGIN ISLANDS',
    nationalFederation: 'BRITISH VIRGIN ISLANDS CANOE AND KAYAK FEDERATION',
  }, {
    ioc: 'BRU',
    country: 'BRUNEI AND DARUSSALAM',
    nationalFederation: 'BRUNEI DARUSSALAM NATIONAL CANOE ASSOCIATION',
  }, {
    ioc: 'BUL',
    country: 'BULGARIA',
    nationalFederation: 'BULGARIAN CANOE FEDERATION',
  }, {
    ioc: 'BUR',
    country: 'BURKINA FASO',
    nationalFederation: 'BURKINA ROWING AND CANOEING FEDERATION',
  }, {
    ioc: 'BDI',
    country: 'BURUNDI',
    nationalFederation: 'FÉDÉRATION NATIONALE DE CANOE DU BURUNDI',
  }, {
    ioc: 'CAM',
    country: 'CAMBODIA',
    nationalFederation: 'CAMBODIAN CANOEING, ROWING AND TRADITIONAL BOAT RACE FEDERATION',
  }, {
    ioc: 'CMR',
    country: 'CAMEROON',
    nationalFederation: 'CAMEROON NAUTICAL SPORTS FEDERATION',
  }, {
    ioc: 'CAN',
    country: 'CANADA',
    nationalFederation: 'CANOE KAYAK CANADA',
  }, {
    ioc: 'CHI',
    country: 'CHILE',
    nationalFederation: 'FEDERACIÓN CHILENA DE CANOTAJE',
  }, {
    ioc: 'TPE',
    country: 'CHINESE TAIPEI',
    nationalFederation: 'CHINESE TAIPEI CANOE ASSOCIATION',
  }, {
    ioc: 'COL',
    country: 'COLOMBIA',
    nationalFederation: 'FEDERACIÓN COLOMBIANA DE CANOTAJE',
  }, {
    ioc: 'COK',
    country: 'COOK ISLANDS',
    nationalFederation: 'COOK ISLANDS CANOE ASSOCIATION (CICA)',
  }, {
    ioc: 'CRC',
    country: 'COSTA RICA',
    nationalFederation: 'FEDERACION COSTARRICENSE DE KAYAK Y CANOTAJE',
  }, {
    ioc: 'CRO',
    country: 'CROATIA',
    nationalFederation: 'CROATIAN CANOE FEDERATION',
  }, {
    ioc: 'CUB',
    country: 'CUBA',
    nationalFederation: 'FEDERACIÓN CUBANA DE CANOA',
  }, {
    ioc: 'CYP',
    country: 'CYPRUS',
    nationalFederation: 'CYPRUS CANOE FEDERATION',
  }, {
    ioc: 'CZE',
    country: 'CZECH REPUBLIC',
    nationalFederation: 'CZECH CANOE UNION - CCU',
  }, {
    ioc: 'PRK',
    country: 'DEMOCRATIC PEOPLES REPUBLIC OF KOREA',
    nationalFederation: 'DPR KOREA CANOE ASSOCIATION',
  }, {
    ioc: 'COD',
    country: 'DEMOCRATIC REPUBLIC OF CONGO',
    nationalFederation: 'FEDERATION CONGOLAISE DE CANOE KAYAK',
  }, {
    ioc: 'TLS',
    country: 'DEMOCRATIC REPUBLIC OF TIMOR-LESTE',
    nationalFederation: 'FEDERAÇAO DE CANOAGEM DE TIMOR-LESTE',
  }, {
    ioc: 'DEN',
    country: 'DENMARK',
    nationalFederation: 'DANSK KANO OG KAJAK FORBUND DKF',
  }, {
    ioc: 'DJI',
    country: 'DJIBOUTI',
    nationalFederation: 'DJIBOUTI ROWING AND CANOE FEDERATION',
  }, {
    ioc: 'DMA',
    country: 'DOMINICA',
    nationalFederation: 'DOMINICA CANOEING AND KAYAKING ASSOCIATION',
  }, {
    ioc: 'DOM',
    country: 'DOMINICAN REPUBLIC',
    nationalFederation: 'FEDERACIÓN DOMINICANA DE REMO Y CANOTAJE (FEDORECA)',
  }, {
    ioc: 'ECU',
    country: 'ECUADOR',
    nationalFederation: 'FEDERACIÓN ECUATORIANA DE CANOTAJE',
  }, {
    ioc: 'EGY',
    country: 'EGYPT',
    nationalFederation: 'THE EGYPTIAN CANOE & KAYAK FEDERATION',
  }, {
    ioc: 'ESA',
    country: 'EL SALVADOR',
    nationalFederation: 'FEDERACION SALVADOREÑA DE CANOTAJE',
  }, {
    ioc: 'ERI',
    country: 'ERITREA',
    nationalFederation: 'ERITREAN ROWING AND CANOEING FEDERATION',
  },
  {
    ioc: 'EST',
    country: 'ESTONIA',
    nationalFederation: 'ESTONIAN CANOEING FEDERATION',
  }, {
    ioc: 'ETH',
    country: 'ETHIOPIA',
    nationalFederation: 'ETHIOPIAN CANOEING FEDERATION',
  }, {
    ioc: 'FIJ',
    country: 'FIJI',
    nationalFederation: 'FIJI OUTRIGGER',
  }, {
    ioc: 'FIN',
    country: 'FINLAND',
    nationalFederation: 'FINNISH CANOE FEDERATION',
  }, {
    ioc: 'FRA',
    country: 'FRANCE',
    nationalFederation: 'FÉDÉRATION FRANÇAISE DE CANOË-KAYAK ET SPORTS DE PAGAIE (FFCK)',
  }, {
    ioc: 'GEO',
    country: 'GEORGIA',
    nationalFederation: 'GEORGIAN NATIONAL CANOE FEDERATION',
  }, {
    ioc: 'GER',
    country: 'GERMANY',
    nationalFederation: 'DEUTSCHER KANU VERBAND E. V.',
  }, {
    ioc: 'GHA',
    country: 'GHANA',
    nationalFederation: 'GHANA ROWING & CANOE ASSOCIATION',
  }, {
    ioc: 'GBR',
    country: 'GREAT BRITAIN',
    nationalFederation: 'BRITISH CANOEING',
  }, {
    ioc: 'GRE',
    country: 'GREECE',
    nationalFederation: 'HELLENIC CANOE-KAYAK FEDERATION',
  }, {
    ioc: 'GUM',
    country: 'GUAM',
    nationalFederation: 'GUAM KAYAK AND CANOE FEDERATION',
  }, {
    ioc: 'GUA',
    country: 'GUATEMALA',
    nationalFederation: 'FEDERACIÓN NACIONAL DE REMO Y CANOTAJE DE GUATEMALA',
  }, {
    ioc: 'GUI',
    country: 'GUINEA',
    nationalFederation: 'FÉDÉRATION GUINÉENNE DE CANOE KAYAK',
  }, {
    ioc: 'GBS',
    country: 'GUINEA-BISSAU',
    nationalFederation: 'FEDERAÇAO DE CANOAGEM DA GUINEA-BISSAU',
  }, {
    ioc: 'GUY',
    country: 'GUYANA',
    nationalFederation: 'GUYANA CANOE FEDERATION',
  }, {
    ioc: 'HON',
    country: 'HONDURAS',
    nationalFederation: 'FEDERACIÓN NACIONAL DE CANOA Y KAYAK DE HONDURAS (FECAKAHO)',
  }, {
    ioc: 'HKG',
    country: 'HONG KONG CHINA',
    nationalFederation: 'HONG KONG CANOE UNION',
  }, {
    ioc: 'HUN',
    country: 'HUNGARY',
    nationalFederation: 'MAGYAR KAJAK-KENU SZÖVETSÉG MKKSZ',
  }, {
    ioc: 'ICF',
    country: 'ICF',
    nationalFederation: 'ICF',
  }, {
    ioc: 'IND',
    country: 'INDIA',
    nationalFederation: 'INDIAN KAYAKING & CANOEING ASSOCIATION',
  }, {
    ioc: 'INA',
    country: 'INDONESIA',
    nationalFederation: 'INDONESIAN ROWING AND CANOEING ASSOCIATION',
  }, {
    ioc: 'IRQ',
    country: 'IRAQ',
    nationalFederation: 'IRAQI CANOE FEDERATION',
  }, {
    ioc: 'IRL',
    country: 'IRELAND',
    nationalFederation: 'CANOEING IRELAND',
  }, {
    ioc: 'IRI',
    country: 'ISLAMIC REPUBLIC OF IRAN',
    nationalFederation: 'IRAN CANOE, ROWING & SAILING FEDERATION',
  }, {
    ioc: 'ISR',
    country: 'ISRAEL',
    nationalFederation: 'ISRAEL CANOE ASSOCIATION',
  }, {
    ioc: 'ITA',
    country: 'ITALY',
    nationalFederation: 'FEDERAZIONE ITALIANA CANOA KAYAK FICK',
  }, {
    ioc: 'CIV',
    country: 'IVORY COAST',
    nationalFederation: 'FÉDÉRATION IVOIRIENNE DE PIROGUE ET CANOÉ KAYAK',
  }, {
    ioc: 'JAM',
    country: 'JAMAICA',
    nationalFederation: 'JAMAICA CANOE KAYAK FEDERATION',
  }, {
    ioc: 'JPN',
    country: 'JAPAN',
    nationalFederation: 'JAPAN CANOE FEDERATION',
  }, {
    ioc: 'KAZ',
    country: 'KAZAKHSTAN',
    nationalFederation: 'CANOE FEDERATION OF KAZAKHSTAN',
  }, {
    ioc: 'KEN',
    country: 'KENYA',
    nationalFederation: 'KENYA ROWING AND CANOE FEDERATION',
  }, {
    ioc: 'TGA',
    country: 'KINGDOM OF TONGA',
    nationalFederation: "NUKU'ALOFA OUTRIGGER CANOE CLUB",
  }, {
    ioc: 'KIR',
    country: 'KIRIBATI',
    nationalFederation: 'KIRIBATI NATIONAL CANOEING FEDERATION INC.',
  }, {
    ioc: 'KOR',
    country: 'KOREA',
    nationalFederation: 'KOREAN CANOE FEDERATION',
  }, {
    ioc: 'KUW',
    country: 'KUWAIT',
    nationalFederation: 'KUWAIT SEA SPORT CLUB',
  }, {
    ioc: 'KGZ',
    country: 'KYRGYZ REPUBLIC',
    nationalFederation: 'CANOE KAYAK FEDERATION OF KYRGYZSTAN',
  }, {
    ioc: 'LAO',
    country: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    nationalFederation: 'LAO CANOE-KAYAK FEDERATION',
  }, {
    ioc: 'LAT',
    country: 'LATVIA',
    nationalFederation: 'LATVIA CANOE FEDERATION',
  }, {
    ioc: 'LBN',
    country: 'LEBANON',
    nationalFederation: 'FÉDÉRATION LIBANAISE DE CANOË-KAYAK',
  }, {
    ioc: 'LBR',
    country: 'LIBERIA',
    nationalFederation: 'LIBERIA ROWING AND CANOEING FEDERATION (LRCF)',
  }, {
    ioc: 'LBA',
    country: 'LIBYA',
    nationalFederation: 'THE GENERAL LIBYAN SAILING AND SEA SPORT FEDERATION',
  }, {
    ioc: 'LTU',
    country: 'LITHUANIA',
    nationalFederation: 'LITHUANIAN CANOE FEDERATION',
  }, {
    ioc: 'LUX',
    country: 'LUXEMBOURG',
    nationalFederation: 'FÉDÉRATION LUXEMBOURGOISE DE CANOE-KAYAK FLCK',
  }, {
    ioc: 'MAC',
    country: 'MACAU CHINA',
    nationalFederation: 'GENERAL ASSOCIATION OF MACAU CANOEING',
  }, {
    ioc: 'MAD',
    country: 'MADAGASCAR',
    nationalFederation: 'MADAGASCAR CANOE KAYAK FEDERATION',
  }, {
    ioc: 'MAW',
    country: 'MALAWI',
    nationalFederation: 'CANOEING ASSOCIATION OF MALAWI',
  }, {
    ioc: 'MAS',
    country: 'MALAYSIA',
    nationalFederation: 'MALAYSIA CANOE ASSOCIATION',
  }, {
    ioc: 'MLI',
    country: 'MALI',
    nationalFederation: 'FEDERATION MALLIENNE DE CANOE KAYAK',
  }, {
    ioc: 'MLT',
    country: 'MALTA',
    nationalFederation: 'MALTA CANOE FEDERATION',
  }, {
    ioc: 'MRI',
    country: 'MAURITIUS',
    nationalFederation: 'MAURITIUS CANOE/KAYAK FEDERATION',
  },
  {
    ioc: 'MEX',
    country: 'MEXICO',
    nationalFederation: 'FEDERACIÓN MEXICANA DE CANOTAJE',
  }, {
    ioc: 'MDA',
    country: 'MOLDOVA',
    nationalFederation: 'KAYAK-CANOE FEDERATION OF MOLDOVA',
  }, {
    ioc: 'MGL',
    country: 'MONGOLIA',
    nationalFederation: 'MONGOLIAN OUTDOOR SPORTS FEDERATION',
  }, {
    ioc: 'MNE',
    country: 'MONTENEGRO',
    nationalFederation: 'MONTENEGRIN CANOE FEDERATION',
  }, {
    ioc: 'MAR',
    country: 'MOROCCO',
    nationalFederation: 'FÉDÉRATION ROYALE MAROCAINE DE CANOE-KAYAK',
  }, {
    ioc: 'MOZ',
    country: ' MOZAMBIQUE',
    nationalFederation: 'FEDERACAO MOCAMBICANA DE VELA E CANOAGEM',
  }, {
    ioc: 'MYA',
    country: 'MYANMAR',
    nationalFederation: 'MYANMAR ROWING & CANOEING FEDERATION',
  }, {
    ioc: 'NAM',
    country: 'NAMIBIA',
    nationalFederation: 'NAMIBIA CANOEING & ROWING FEDERATION',
  }, {
    ioc: 'NEP',
    country: 'NEPAL',
    nationalFederation: 'NEPAL RAFTING AND CANOEING ASSOCIATION',
  }, {
    ioc: 'NED',
    country: 'NETHERLANDS',
    nationalFederation: 'WATERSPORTVERBOND',
  }, {
    ioc: 'NZL',
    country: 'NEW ZEALAND',
    nationalFederation: 'NEW ZEALAND CANOEING FEDERATION INC.',
  }, {
    ioc: 'NCA',
    country: 'NICARAGUA',
    nationalFederation: 'FEDERACIÓN NICARAGÜENSE DE CANOTAJE',
  }, {
    ioc: 'NGR',
    country: 'NIGERIA',
    nationalFederation: 'NIGERIAN ROWING, CANOEING AND SAILING FEDERATION',
  }, {
    ioc: 'NOR',
    country: 'NORWAY',
    nationalFederation: 'NORGES PADLEFORBUND',
  }, {
    ioc: 'PAK',
    country: 'PAKISTAN',
    nationalFederation: 'PAKISTAN CANOE AND KAYAK FEDERATION',
  }, {
    ioc: 'PLW',
    country: 'PALAU',
    nationalFederation: 'CANOE ASSOCIATION OF PALAU',
  }, {
    ioc: 'PLE',
    country: 'PALESTINE',
    nationalFederation: 'PALESTINE ROWING AND CANOEING FEDERATION',
  }, {
    ioc: 'PNG',
    country: 'PAPUA NEW GUINE',
    nationalFederation: 'APAPUA NEW GUINEA CANOEING ASSOCIATION INC.',
  }, {
    ioc: 'PAR',
    country: 'PARAGUAY',
    nationalFederation: "FEDERACIÓN PARAGUAYA DE CANOTAJE 'FEPACA'",
  }, {
    ioc: 'CHN',
    country: 'PEOPLES REPUBLIC OF CHINA',
    nationalFederation: 'CHINESE CANOEING ASSOCIATION',
  }, {
    ioc: 'PER',
    country: 'PERU',
    nationalFederation: 'FEDERACION DEPORTIVA NACIONAL DE CANOTAJE',
  }, {
    ioc: 'PHI',
    country: 'PHILIPPINES',
    nationalFederation: 'PHILIPPINE CANOE & KAYAK FEDERATION',
  }, {
    ioc: 'POL',
    country: 'POLAND',
    nationalFederation: 'POLISH CANOE FEDERATION',
  }, {
    ioc: 'POR',
    country: 'PORTUGAL',
    nationalFederation: 'FEDERAÇÃO PORTUGUESA DE CANOAGEM',
  }, {
    ioc: 'PUR',
    country: 'PUERTO RICO',
    nationalFederation: 'FEDERACIÓN PUERTORRIQUEÑA DE CANOTAJE',
  }, {
    ioc: 'QAT',
    country: 'QATAR',
    nationalFederation: 'QATAR SAILING AND ROWING FEDERATION',
  }, {
    ioc: 'GEQ',
    country: 'REPUBLIC OF EQUATORIAL-GUINEA',
    nationalFederation: 'FEDERACIÓN ECUATOGUINEANA DE CANOA/KAYAK',
  }, {
    ioc: 'MKD',
    country: 'REPUBLIC OF NORTH MACEDONIA',
    nationalFederation: 'MACEDONIAN CANOE FEDERATION',
  }, {
    ioc: 'UZB',
    country: 'REPUBLIC OF UZBEKISTAN',
    nationalFederation: 'THE ROWING AND CANOEING FEDERATION OF UZBEKISTAN',
  }, {
    ioc: 'PAN',
    country: 'REPUBLICA DE PANAMA',
    nationalFederation: 'UNIÓN PANAMEÑA DE REMO AFICIONADO',
  }, {
    ioc: 'ROU',
    country: 'ROMANIA',
    nationalFederation: 'FEDERATIA ROMANA DE KAIAC-CANOE',
  }, {
    ioc: 'RUS',
    country: 'RUSSIA',
    nationalFederation: 'RUSSIAN CANOE FEDERATION',
  }, {
    ioc: 'VIN',
    country: 'SAINT VINCENT AND THE GRENADINES',
    nationalFederation: 'SAINT VINCENT AND THE GRENADINES CANOE ASSOCIATION',
  }, {
    ioc: 'SAM',
    country: 'SAMOA',
    nationalFederation: "FAALAPOTOPOTOGA ALO VA'A SAMOA - SAMOA OUTRIGGER CANOE ASSOCIATION (SOCA)",
  }, {
    ioc: 'STP',
    country: 'SAO TOME AND PRINCIPE',
    nationalFederation: 'SAO TOME AND PRINCIPE CANOE FEDERATION',
  }, {
    ioc: 'SEN',
    country: 'SENEGAL',
    nationalFederation: 'FEDERATION SENEGALAISE DE CANOE KAYAK',
  }, {
    ioc: 'SRB',
    country: 'SERBIA',
    nationalFederation: 'CANOE FEDERATION OF SERBIA',
  }, {
    ioc: 'SEY',
    country: 'SEYCHELLES',
    nationalFederation: 'SEYCHELLES CANOE ASSOCIATION',
  }, {
    ioc: 'SGP',
    country: 'SINGAPORE',
    nationalFederation: 'SINGAPORE CANOE FEDERATION',
  }, {
    ioc: 'SXM',
    country: 'SINT MAARTEN',
    nationalFederation: 'SINT MAARTEN CANOE FEDERATION',
  }, {
    ioc: 'SVK',
    country: 'SLOVAKIA',
    nationalFederation: 'SLOVAK CANOEING',
  }, {
    ioc: 'SLO',
    country: 'SLOVENIA',
    nationalFederation: 'CANOE FEDERATION OF SLOVENIA',
  }, {
    ioc: 'SOM',
    country: 'SOMALIA',
    nationalFederation: 'SOMALI ROWING AND CANOEING FEDERATION',
  }, {
    ioc: 'RSA',
    country: 'SOUTH AFRICA',
    nationalFederation: 'CANOEING SOUTH AFRICA',
  }, {
    ioc: 'ESP',
    country: 'SPAIN',
    nationalFederation: 'REAL FEDERACIÓN ESPAÑOLA DE PIRAGÜISMO',
  }, {
    ioc: 'SRI',
    country: 'SRI LANKA',
    nationalFederation: 'NATIONAL ASSOCIATION OF CANOEING AND KAYAKING IN SRI LANKA',
  }, {
    ioc: 'SUD',
    country: 'SUDAN',
    nationalFederation: 'SUDANESE ROWING & CANOE FEDERATION',
  }, {
    ioc: 'SUR',
    country: 'SURINAME',
    nationalFederation: 'SURINAME CANOE FEDERATION',
  }, {
    ioc: 'SWE',
    country: 'SWEDEN',
    nationalFederation: 'SVENSKA KANOTFÖRBUNDET SKF',
  }, {
    ioc: 'SUI',
    country: 'SWITZERLAND',
    nationalFederation: 'SCHWEIZERISCHER KANU-VERBAND (SKV)',
  }, {
    ioc: 'TAH',
    country: 'TAHITI',
    nationalFederation: 'FEDERATION TAHITIENNE DE KAYAK',
  }, {
    ioc: 'TJK',
    country: 'TAJIKISTAN',
    nationalFederation: 'CANOE FEDERATION OF TAJIKISTAN',
  }, {
    ioc: 'THA',
    country: 'THAILAND',
    nationalFederation: 'THE ROWING AND CANOEING ASSOCIATION OF THAILAND',
  }, {
    ioc: 'TOG',
    country: 'TOGO',
    nationalFederation: "FEDERATION TOGOLAISE D'AVIRON ET DE CANOE",
  }, {
    ioc: 'TTO',
    country: 'TRINIDAD AND TOBAGO',
    nationalFederation: 'TRINIDAD & TOBAGO CANOEING & ROWING FEDERATION',
  }, {
    ioc: 'TUN',
    country: 'TUNISIA',
    nationalFederation: 'FÉDÉRATION TUNISIENNE DE CANOE-KAYAK',
  }, {
    ioc: 'TUR',
    country: 'TURKEY',
    nationalFederation: 'TURKISH CANOE FEDERATION',
  }, {
    ioc: 'TKM',
    country: 'TURKMENISTAN',
    nationalFederation: 'TURKMENISTAN CANOE FEDERATION',
  }, {
    ioc: 'UGA',
    country: 'UGANDA',
    nationalFederation: 'UGANDA CANOE FEDERATION',
  }, {
    ioc: 'UKR',
    country: 'UKRAINE',
    nationalFederation: 'UKRAINIAN CANOE FEDERATION',
  }, {
    ioc: 'UAE',
    country: 'UNITED ARAB EMIRATES',
    nationalFederation: 'UAE MARINE SPORTS FEDERATION',
  }, {
    ioc: 'USA',
    country: 'UNITED STATES OF AMERICA',
    nationalFederation: 'AMERICAN CANOE ASSOCIATION (ACA)',
  }, {
    ioc: 'URU',
    country: 'URUGUAY',
    nationalFederation: 'FEDERACIÓN URUGUAYA DE CANOTAJE',
  }, {
    ioc: 'VAN',
    country: 'VANUATU',
    nationalFederation: 'VANUATU CANOEING ASSOCIATION',
  }, {
    ioc: 'VEN',
    country: 'VENEZUELA',
    nationalFederation: 'FEDERACIÓN VENEZOLANA DE CANOTAJE',
  }, {
    ioc: 'VIE',
    country: 'VIETNAM',
    nationalFederation: 'VIETNAM CANOEING, ROWING AND SAILING FEDERATION',
  }, {
    ioc: 'ZAM',
    country: 'ZAMBIA',
    nationalFederation: 'ZAMBIA AMATEUR ROWING AND CANOEING ASSOCIATION',
  },
];

export default federations;
