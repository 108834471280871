import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

// - styles
import GlobalStyle from 'assets/styles/global';

// - components
import Header from 'components/Header';
import Footer from 'components/Footer';

// - containers
import { LanguageProvider } from 'containers/Language';

// - pages
import Home from 'pages/Home';
import AboutCOPAC from 'pages/AboutCOPAC';
import Statutes from 'pages/Statutes';
import BoardOfDirectors from 'pages/BoardOfDirectors';
import Committees from 'pages/Committees';
import Documents from 'pages/Documents';
import NationalFederations from 'pages/NationalFederations';
import Disciplines from 'pages/Disciplines';
import CanoeSprint from 'pages/CanoeSprint';
import News from 'pages/News';
import Contact from 'pages/Contact';
import Events from 'pages/Events';
import EventsItem from 'pages/EventsItem';
import NotFound404 from 'pages/NotFound404';
import NewsItem from 'pages/NewsItem';

function App() {
  return (
    <LanguageProvider>
      <Router>
        <GlobalStyle />
        <Header />

        <Switch>
          <Route path="/" exact component={Home} />

          {/* -- About -- */}
          <Route path="/about" exact component={AboutCOPAC} />
          <Route path="/about/statutes" component={Statutes} />
          <Route path="/about/board-of-directors" component={BoardOfDirectors} />
          <Route path="/about/committees" component={Committees} />
          <Route path="/about/documents" component={Documents} />
          <Route path="/about/national-federations" component={NationalFederations} />

          {/* -- Discliplines -- */}
          <Route path="/disciplines" exact component={Disciplines} />
          {/* <Route path="/disciplines/canoe-sprint" component={CanoeSprint} /> */}
          <Route path="/disciplines/:id" component={CanoeSprint} />

          {/* -- News -- */}
          <Route exact path="/news" component={News} />
          {/* <Route path="/news/:id/:title" component={NewsItem} /> */}
          <Route path="/news/:id" component={NewsItem} />

          <Route path="/contact" component={Contact} />

          <Route exact path="/events" component={Events} />
          <Route path="/events/:id" component={EventsItem} />


          <Route path="/404" component={NotFound404} />
          <Redirect to="/404" />
        </Switch>

        <Footer />
      </Router>
    </LanguageProvider>
  );
}

export default App;
