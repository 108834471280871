import styled from 'styled-components';

import { colors } from 'assets/styles/colors';

export const Form = styled.form`
  background-color: #f3f3f3;
  padding: 30px 30px;
  margin-bottom: 30px;

  @media (min-width: 992px) {
    padding: 30px 30px 50px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  color: #0c2850;
  background-color: #ffffff;
  height: 48px;
  line-height: 48px;
  border: none;
  margin-bottom: 20px;

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const Select = styled.select`
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none !important;
  -webkit-border-radius: 0px;
  background-color: white;
  border: none;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  color: #003063;
  line-height: normal;
  border-radius: 0;
  height: 48px;
  line-height: 48px;
  margin-bottom: 20px;

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const Arrow = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  right: 15px;
  bottom: 20px;
  background-color: ${colors.yellow};
  pointer-events:none;

  @media (min-width: 992px) {
    margin-bottom: 0;
    bottom: 0px;
  }
`;

export const Button = styled.button`
  background-color: ${colors.yellow};
  color: #fff;
  height: 48px;
  padding: 0 20px;
  border: 0;
  font-size: 17px;

  &:focus {
    outline: 0;
  }

  margin-bottom: 20px;

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;
