import React, { useState, createContext, useContext } from 'react';

import { languageOptions, dictionaryList } from 'assets/languages';

// Cria o Context da linguagem com a linguagem default selecionada
export const LanguageContext = createContext({
  userLanguage: 'en',
  dictionary: dictionaryList.en,
});

// Configua o Provider da linguagem
export function LanguageProvider({ children }) {
  const [userLanguage, setUserLanguage] = useState('en');

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: (selected) => {
      const newLanguage = languageOptions[selected] ? selected : 'en';
      setUserLanguage(newLanguage);
      window.localStorage.setItem('rcml-lang', newLanguage);
      document.documentElement.lang = selected;
    },
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
}

// Pega o texto de acordo com a linguagem selecionada
export function Text({ content }) {

  const languageContext = useContext(LanguageContext);

  // Aplica quebra de linha (insere <br/> na renderização) caso exista '\n' na string
  // eslint-disable-next-line
  String.prototype.applyLinebreak = function () {
    return this.split('\n').map((it, i) => (
      <React.Fragment key={i}>
        {i > 0 ? <br /> : ''}
        {it}
      </React.Fragment>
    ));
  };

  if (!languageContext.dictionary[content]) {
    console.log({errorLanguageContextDictionary: content});
    return content;
  }

  return (
    languageContext.dictionary[content].applyLinebreak() || content.applyLinebreak()
  );
}
