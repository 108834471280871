import React, { useContext } from 'react';

import { TextBox, BlockQuote } from '../styles/quoteStyles';

// - tradução
import { LanguageContext } from 'containers/Language';

function Quote() {
  const { userLanguage } = useContext(LanguageContext);

  return (
    <TextBox>
      <BlockQuote>
        <span>
          <h5>
          {
            (
              userLanguage === 'es'
                ? (
                  'Gestionar, promover y representar la canoje en las Américas con Federaciones Nacionales, promoviendo la práctica de la canoa y el kaiak desde la iniciación hasta el alto rendimiento. Ampliar el desarrollo del la canotaje y promover la participación de equipos del Continente Americano.'
                )
                : (
                  'Manage, promote and represent canoe in the Americas with National Federations, promoting the practice of canoe from initiation to high performance. Expand the development of canoeing and promote the participation of teams from the American Continent.'
                )
            )
          }
          </h5>
        </span>
      </BlockQuote>
    </TextBox>
  );

  /* return (
    <TextBox>
      <BlockQuote>
        <span>
          <h5>
            Lorem ipsum dolor sit amet consectetuer adipiscing elit Suspendisse et justo Praesent mattis commodo augue Aliquam ornare hendrerit augue Cras tellus In pulvinar lectus a est Curabitur eget orci.
          </h5>
        </span>
      </BlockQuote>
    </TextBox>
  ); */
}

export default Quote;
