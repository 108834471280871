import React, { useState, useEffect, useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { useParams } from 'react-router-dom';

import PageHeader from 'components/PageHeader';

// - react-bootstrap
import { Container, Row, Col } from 'react-bootstrap';

// - api
//import { portalApi } from 'services/api';

// - styles
import { NewsTitle, NewsImg, NewsContent, PostInfo } from './styles';

// - tradução
import { LanguageContext } from 'containers/Language';

// -- FontAwesome
import { faUser, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//const imgBaseURL = 'http://www.canoagem.org.br/arquivos/noticias/destaque/';

import Cover from 'assets/img/newsitem.png';

const origin = process.env.REACT_APP_BASE_URL;

function NewsItem() {
  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'News',
    },
  ];

  const { userLanguage } = useContext(LanguageContext);

  const [newsItem, setNewsItem] = useState();

  const { id } = useParams();

  useEffect(() => {
    fetch(`${origin}/api/news/${id}`)
      .then(async (response) => {
        const json = await response.json();
        setNewsItem(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, [id]);

  return (
    <>
      <PageHeader
        bannerTitle="Copac News"
        bannerSubtitle="Latest news about canoe"
        pageTitle="News"
        breadcrumbs={breadcrumbs}
        img={Cover}
      />

      {!newsItem ? (
        <Container>
          <Row className="justify-content-center py-4 my-4"></Row>
        </Container>
      ) : (
        <Container>
          <Row className="justify-content-center py-4 my-4">
            <Col>
              <NewsTitle>
                {/* {newsItem.titulo} */}
                {userLanguage === 'es'
                  ? newsItem.spanish.title
                  : newsItem.english.title}
              </NewsTitle>
              <h5 style={{ color: '#727272' }}>
                {/* {newsItem.resumo} */}
                {userLanguage === 'es'
                  ? newsItem.spanish.subheadline
                  : newsItem.english.subheadline}
              </h5>
              <NewsImg
                src={`${origin}${newsItem.imagePath}`}
                alt=""
                className="img-responsive"
              />
              {/* <div dangerouslySetInnerHTML={{ __html: newsItem.corpo }} /> */}
              <PostInfo>
                <span>
                  <FontAwesomeIcon icon={faUser} />
                  {newsItem.author}
                </span>
                <span>
                  <FontAwesomeIcon icon={faCalendar} />
                  {`
                          ${new Date(newsItem.createdAt).toLocaleDateString(
                            userLanguage
                          )}

                          ${new Date(newsItem.createdAt).toLocaleTimeString(
                            userLanguage
                          )}
                        `}
                </span>
              </PostInfo>
              <NewsContent>
                {/* {ReactHtmlParser(newsItem.corpo)} */}
                {userLanguage === 'es'
                  ? ReactHtmlParser(newsItem.spanish.body)
                  : ReactHtmlParser(newsItem.english.body)}
              </NewsContent>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default NewsItem;
