import React from 'react';

import PageHeader from 'components/PageHeader';

// - react-bootstrap
import { Container/* , Row */ } from 'react-bootstrap';

// - data
// import disciplines from 'assets/disciplines';
// import events from 'assets/events';

// - sections
//import Event from './sections/Event';
import EventTable from './sections/EventTable';

// - styles
// import { FilterButton } from './styles';

import Cover from 'assets/img/events.png';

function Events() {
  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'Events',
    },
  ];

  return (
    <>
      <PageHeader bannerTitle="events" pageTitle="events" breadcrumbs={breadcrumbs} img={Cover} />

      <div className="clearfix" />

      <Container style={{ padding: '24px 15px' }}>

        <EventTable />

      </Container>
    </>
  );
}

export default Events;
