import styled from 'styled-components';

export const Underline = styled.div`
  margin: 0 auto 15px auto;
  width: 34px;
  height: 2px;
  float: none;
  padding: 0px;
  background-color: #161616;
`;

export const Title = styled.h3`
  text-transform: uppercase;
`;

export const SubTitle = styled.p`
  margin: 0 auto 70px auto;
`;

export const OverlayWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: default;

  & img {
    position: relative;
    display: block;
    max-width: 100%;
    height: auto;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
  }

  &:hover img{
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
`;

export const Overlay = styled.div`
  opacity: 0;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
  padding: 30px;
  height: 100%;
  background-color: rgba(0,0,0,0.9);
  color: #fff;

  &:hover {
    opacity: 1;
  }

  & h3 {
    color: #fff;
    font: 400 16px/21px "Open Sans", sans-serif;
    margin-bottom: 0;
  }

  & p {
    color: #aaa;
    font: 400 12px/16px "Open Sans", sans-serif;
  }
`;
