import React from 'react';

// - Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

// - styles
import { colors } from 'assets/styles/colors';
import { ListItem } from '../styles/historyStyles';
import { Divider } from '../styles';

import { Text } from 'containers/Language';

function History() {
  const iconColor = colors.yellow;

  return (
    <>
      <h3><Text content='history'/></h3>
      <Divider />
      <br />
      <p>
        The canoe and kayak have been part of the culture of the American continent for millennia, however, the first official competition of Canoe Print on our continent, took place at the 1987 Pan American Games in Indianapolis, USA.
      </p>
      <p>
        They participated in the inaugural competition: Argentina, Canada, Costa Rica, Cuba, Uruguay and the USA as the host country. All the Federations were invited by their respective Olympic Committees since until that moment there was no official Confederation regulating canoe in America.
      </p>
      <p>
        They were part of that first effort: Chic Dambach from the USA, Arturo Canovi (ARG) and Henry Gómez (URU). Charles Yatman served as a US delegate in that historic event.
      </p>
      <p>
        In the next four years there was a continuous interest in the development of the Pan American Organization, and in 1991 in Havana; the so-called “Pan-American Canoe Council” was formed chaired by Ricardo Monardes (CHI)
      </p>
      <p>  Then meetings were held every two years with the assistance of delegates from different countries</p>
      <br />
      <ul className="iconlist less-margin orange2">
        <ListItem>
          <FontAwesomeIcon icon={faCheck} color={iconColor} />
          In 1993 in South America the following representatives met Benjamin Szyferman (ARG); Christian Moya (CHI); Ernesto Salazar (VEN); João Tomasini Schwertner (BRA) and Pablo Veliz (CUB).
        </ListItem>
        <ListItem>
          <FontAwesomeIcon icon={faCheck} color={iconColor} />
          In 1995 in Mar del Plata (ARG), the original name of “Pan-American Canoe Council” was changed to the current “Pan American Canoe Federation (COPAC)”
        </ListItem>
        <ListItem>
          <FontAwesomeIcon icon={faCheck} color={iconColor} />
          From that moment COPAC was recognized as the official organization of the American continent, receiving the support of the International Canoe Federation (ICF) through its President Mr. Sergio Orsi and its Vice President Mr. José Perurena López
        </ListItem>
        <ListItem>
          <FontAwesomeIcon icon={faCheck} color={iconColor} />
          In 1995 they were elected:
          <ul className="internal-list">
            <ListItem>President: Pablo Veliz (CUB)</ListItem>
            <ListItem>1st Vice President: Arturo Liebers (BOL)</ListItem>
            <ListItem>2nd Vice President: Charles Yatman (USA)</ListItem>
            <ListItem>Treasurer: Francisco Valdez (CUB)</ListItem>
          </ul>
        </ListItem>
        <ListItem>
          <FontAwesomeIcon icon={faCheck} color={iconColor} />
          In the same year, the representativeness of COPAC was accepted before the board of the ICF, recognizing up to three representatives.
        </ListItem>
        <ListItem>
          <FontAwesomeIcon icon={faCheck} color={iconColor} />
          Over the years, the following officers have served at the COPAC: Andy Toro (USA), Robert Sleeth (CAN), José Sade and René Romero (CUB), Ariel Arbo (ARG), João Tomasini Schwertner (BRA). Currently the continental representatives of COPAC are Charles Yatman, North America; Víctor Ruiz, Centro América and Cecilia Farías for South America.
        </ListItem>
        <ListItem>
          <FontAwesomeIcon icon={faCheck} color={iconColor} />
          In 1997 there was the unfortunate death of President Pablo Veliz. It was then necessary to call an extraordinary Congress which was held in Brazil, where Mr. Charles Yatman was elected as new President of the COPAC. He was subsequently reelected as president in Winnipeg (1999) Santo Domingo (2003) and Rio de Janeiro (2007)
        </ListItem>
        <ListItem>
          <FontAwesomeIcon icon={faCheck} color={iconColor} />
          In 1997 COPAC represented by its President, Mr. Charles Yatman is invited to join and be part of the meetings of the Association of Pan American Sports Confederations (ACODEPA), of which COPAC remains an active member.
        </ListItem>
      </ul>
    </>
  );
}

export default History;
