import styled from 'styled-components';

export const Title = styled.h5`
  text-transform: uppercase;
`;

export const Divider = styled.div`
  margin: 20px 0;
  border-bottom: 1px solid;
`;
