import React from 'react';

// - styles
import { Divider } from '../styles';

function Media() {
  return (
    <>
      <h3>COPAC TV</h3>
      <Divider />
      <br />
      <div className="mt-2">
        <iframe width="550" height="315" title="copac-tv" src="https://www.youtube.com/embed/84mcVr23jf8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      </div>
    </>
  );
}

export default Media;
