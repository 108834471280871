import styled from 'styled-components';

import { colors } from 'assets/styles/colors';

export const CommitteesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const TeamHolder = styled.div`
  width: 100%;
  padding: 24px 15px;
  float: left;
  margin: 0px;
  z-index: 0;
  background-color: #fff;
  //transition: all 0.3s ease-out 0s;
  text-align: center;
`;

export const ImageHolder = styled.div`
  position: relative;
  width: 100%;
  float: left;
  top: 0px;
  left: 0px;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  z-index: 1;

  /* & .hover-box {
    position: absolute;
    width: 100%;
    float: left;
    bottom: -200px;
    left: 0px;
    padding: 30px;
    margin: 0px;
    z-index: 2;
    transition: all 0.3s ease-out 0s;
    background-color: ${colors.yellow};
  } */

  & img {
    position: relative;
    top: 0px;
    left: 0px;
    //transition: all 0.3s ease-out 0s;
    width: 100%;
    height: 300px;

  }

 /*  &:hover  {

    & .hover-box {
      bottom: 0px;
    }

    // in order to lift the image
    //para levantar a imagem
     //& img {
      //top: -40px;
    //}

  } */
`;

export const Name = styled.span`
  color: ${colors.yellow};
`;
