import styled from 'styled-components';

export const Title = styled.h3`
  color: #444;
  position: relative;
  font-weight: 400;
  margin-bottom: 25px;

  &::after {
    left: 0;
    right: 0;
    width: 60px;
    bottom: -10px;
    content: '';
    position: absolute;
    border-image: linear-gradient(60deg, #F6B800, #cc9900);
    border-bottom: 7px solid transparent;
    border-image-slice: 1;
  }
`;

export const IconHolder = styled.div`
  width: 56px;
  display: flex;
  justify-content: center;
  margin-right: 30px;
  margin-bottom: 70px;
`;
