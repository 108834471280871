import React, { useState, useEffect, useContext, useRef } from 'react';

import styled from 'styled-components';

// import Carousel from 'react-bootstrap/Carousel';
import Slider from 'react-slick';

// -- CSS de apoio
import 'assets/styles/carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// -- imagens para os banners
// import banner from 'assets/img/sliders/masterslider/banner001.png';

// - tradução
import { LanguageContext } from 'containers/Language';

const origin = process.env.REACT_APP_BASE_URL;

function NextArrow(props) {
  const { onClick } = props;
  return (
    <button className="carousel-control-prev" onClick={onClick}>
      <span aria-hidden="true" className="carousel-control-prev-icon" />
      <span className="sr-only">Previous</span>
    </button>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <button className="carousel-control-next" onClick={onClick}>
      <span aria-hidden="true" className="carousel-control-next-icon" />
      <span className="sr-only">Next</span>
    </button>
  );
}

export const NewsTitle = styled.h3`
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 20px;
  }
`;

export default function BannerCarousel({ Text }) {
  const [slide, setSlide] = useState({
    oldSlide: 0,
    activeSlide: 0,
  });
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 300,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) =>
      setSlide({ oldSlide: current, activeSlide: next }),
  };

  const animationTitle = {
    WebkitAnimationDelay: '.7s',
    animationDelay: '.7s',
    textShadow: '0 0 6px rgb(54 54 54 / 60%)',
  };

  /* const animationText = {
    WebkitAnimationDelay: '1.4s',
    animationDelay: '1.4s'
  } */

  const animationButton = {
    WebkitAnimationDelay: '2.1s',
    animationDelay: '2.1s',
  };

  const [newsList, setNewsList] = useState([]);

  const { userLanguage } = useContext(LanguageContext);

  const firstRender = useRef(123);

  /* console.log({ activeSlide: slide.activeSlide, firstRender: firstRender.current }); */

  useEffect(() => {
    fetch(`${origin}/api/news?page=1&perPage=4`)
      .then(async (response) => {
        const json = await response.json();
        setNewsList(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []);

  /* if (slide.activeSlide === 1) {
    firstRender.current = false;
    return;
  } */

  return (
    <div className="carousel slide">
      <Slider className="carousel-inner " {...settings}>
        {newsList.map((newsItem) => (
          <div
            className={`carousel-item ${firstRender && 'active'}`}
            key={newsItem._id}
          >
            <img
              className="d-block w-100 height"
              /* src={banner} */
              src={`${origin}${newsItem.imagePath}`}
              alt="First slide"
            />
            <div className="carousel-caption">
              <NewsTitle
                className={`text1 ${firstRender && 'slide-right'}`}
                style={animationTitle}
              >
                {/* <Text content="welcomeTitle" /> */}
                {userLanguage === 'es'
                  ? newsItem.spanish.title
                  : newsItem.english.title}
              </NewsTitle>
              {/* <h3 className={`text3 ${slide.activeSlide === 0 && 'slide-right'}`} style={animationText}>
              <Text content="welcomeDescription" />
              {
                (
                  userLanguage === 'es'
                    ? newsItem.spanish.subheadline
                    : newsItem.english.subheadline
                )
              }
            </h3> */}
              <a
                href={`/news/${newsItem._id}`}
                className={`button ${firstRender && 'slide-right'}`}
                style={animationButton}
              >
                <Text content="readMore" />
              </a>
            </div>
          </div>
        ))}

        {/* <div className={`carousel-item ${slide.activeSlide === 0 && 'active'}`}>
          <img
            className="d-block w-100 height"
            src={banner}
            alt="First slide"
          />
          <div className="carousel-caption">
            <h3 className={`text1 ${slide.activeSlide === 0 && 'slide-right'}`} style={animationTitle}>
              <Text content="welcomeTitle" />
            </h3>
            <h3 className={`text3 ${slide.activeSlide === 0 && 'slide-right'}`} style={animationText}>
              <Text content="welcomeDescription" />
            </h3>
            <a href="#read-more" className={`button ${slide.activeSlide === 0 && 'slide-right'}`} style={animationButton}>
              <Text content="readMore" />
            </a>
          </div>
        </div> */}
      </Slider>
    </div>
  );
}
