import styled from 'styled-components';

export const DisciplineTitle = styled.h5`
  text-transform: uppercase;
  margin-bottom: 3px;
`;

export const Divider = styled.div`
  margin: 20px 0;
  border-bottom: 1px solid #e4e4e4;
`;

export const Button = styled.a`
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 10px 36px;
  margin: 0px;
  box-shadow: none;
  border-radius: 0;
  color: #fff;
  background-color: #ffc000;

  &:hover {
    color: #fff;
    background-color: #242424;
  }
`;
