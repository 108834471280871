import React from 'react';

// - react-bootstrap
import { Container, Col, Row } from 'react-bootstrap';

// - styles
import {
  Title,
  Status,
} from './styles';

function NotFound404() {
  return (
    <Container>
      <Row className="text-center align-items-center" style={{ minHeight: '60vh' }}>
        <Col>
          <Title>Página não encontrada</Title>
          <Status>404</Status>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound404;
