import styled from 'styled-components';

export const Button = styled.button`
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  text-transform: uppercase;
  margin-right: 5px;
  padding: 0 10px;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #fff;
    color: #000;
  }
`;
