import React, { useState, useEffect, useContext } from 'react';
// import { Link } from 'react-router-dom';

// - react-bootstrap
import { Col, Row } from 'react-bootstrap';

// - Imagens
// import news1 from 'assets/img/news/CANADA-400x200.jpg';
// import news2 from 'assets/img/news/FOTO-CAPA-LUIS-CARDOSO-250.png';
// import news3 from 'assets/img/news/SEBAS-400.jpg';

// - styles
import { NewsTitle, ImageHolder, Divider, Button } from '../styles/newsStyles';

// - tradução
import { LanguageContext } from 'containers/Language';

const origin = process.env.REACT_APP_BASE_URL;

function News({ Text, disciplineId }) {
  const [news, setNews] = useState([]);

  const { userLanguage } = useContext(LanguageContext);

  useEffect(() => {
    fetch(`${origin}/api/disciplines/${disciplineId}/news`)
      .then(async (response) => {
        const json = await response.json();
        setNews(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, [disciplineId]);

  if (news.length === 0) {
    return null;
  }

  return (
    <Row style={{ marginBottom: 50 }}>
      {news.map((newsItem) => (
        <Col lg="4" className="py-4 d-flex flex-column justify-content-between">
          <div className="mb-4">
            <ImageHolder>
              <img
                src={`${origin}${newsItem.imagePath}`}
                className="img-responsive"
                alt=""
                style={{ height: '220px', width: '100%' }}
              />
            </ImageHolder>
            <NewsTitle>
              {userLanguage === 'es'
                ? newsItem.spanish.title
                : newsItem.english.title}
            </NewsTitle>
            <Divider />
            {userLanguage === 'es'
              ? newsItem.spanish.subheadline
              : newsItem.english.subheadline}
          </div>
          <Button to={`/news/${newsItem._id}`} /* target='_blank' */>
            <Text content="readMore" />
          </Button>
        </Col>
      ))}
    </Row>
  );

  /* return (
    <Row style={{ marginBottom: 50 }}>
      <Col lg="4" className="py-4 d-flex flex-column justify-content-between">
        <div className="mb-4">
          <ImageHolder>
            <img src={news1} className="img-responsive" alt="2020 Canadian Sprint and Paracanoe International Season" />

          </ImageHolder>
          <NewsTitle>2020 Canadian Sprint and Paracanoe International Season</NewsTitle>
          <Divider />
          <p>
            Due to the ongoing COVID-19 pandemic, Canada Kayak Canada (CKC) has made the difficult decision to not proceed with nominating Sprint and Paracanoe International teams for the entire 2020 competition season
          </p>
        </div>
        <Button href="#news"><Text content="readMore" /></Button>
      </Col>

      <Col lg="4" className="py-4 d-flex flex-column justify-content-between">
        <div className="mb-4">
          <ImageHolder>
            <img src={news2} className="img-responsive" alt="World Champion in Paracanoe dreams of gold at the Tokyo Games" />

          </ImageHolder>
          <NewsTitle>World Champion in Paracanoe dreams of gold at the Tokyo Games</NewsTitle>
          <Divider />
          <p>
            Amid the pandemic, the Brazilian Luís Carlos Cardoso trains at home FROM: BRAZILIAN CANOE The canoeist Luís Carlos Cardoso is guaranteed at the Paralympics in Tokyo (Japan), the second in the athlete’s career
          </p>
        </div>
        <Button href="#news"><Text content="readMore" /></Button>
      </Col>

      <Col lg="4" className="py-4 d-flex flex-column justify-content-between">
        <div className="mb-4">
          <ImageHolder>
            <img src={news3} className="img-responsive" alt="Sebas Rossi trains in his girlfriend’s pool to prep for 2021 Olympics" />

          </ImageHolder>
          <NewsTitle>Sebas Rossi trains in his girlfriend’s pool to prep for 2021 Olympics</NewsTitle>
          <Divider />
          <p>
            PILAR, BUENOS AIRES PROVINCE, ARGENTINA (JUNE 8, 2020) (REUTERS – ACCESS ALL) 1. VARIOUS OF ARGENTINE WILDWATER CANOEING SLALOM RACER, SEBASTIAN ROSSI, TRAINING IN GIRLFRIEND’S SWIMMING POOL 2.
          </p>
        </div>
        <Button href="#news"><Text content="readMore" /></Button>
      </Col>
    </Row>
  ); */
}

export default News;
