/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';

// - react-bootstrap
import { Nav, Row } from 'react-bootstrap';

import LanguageSelector from 'components/LanguageSelector';

// - Texto traduzido
import { Text } from 'containers/Language';

// - Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

// - images
import logo from 'assets/img/logo/main-logo.png';

// - styles
import { Topbar, NavBar, NavLink, Dropdown } from './styles';

// - tradução
import { LanguageContext } from 'containers/Language';

const origin = process.env.REACT_APP_BASE_URL;

function Header() {
  const [isSticky, setSticky] = useState(false);
  const headerRef = useRef(null);

  const [disciplines, setDisciplines] = useState([]);

  const { userLanguage } = useContext(LanguageContext);

  const handleScroll = () => {
    if (headerRef.current) {
      setSticky(
        window.pageYOffset > headerRef.current.getBoundingClientRect().top
      );
    }
  };

  useEffect(() => {
    // run once the component is loaded - componentDidMount()
    window.addEventListener('scroll', handleScroll);

    fetch(`${origin}/api/disciplines`)
      .then(async (response) => {
        const json = await response.json();
        setDisciplines(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });

    return () => {
      // run after the component is removed - componentWillUnmount()
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <>
      <Topbar>
        <Row className="justify-content-between px-3">
          <LanguageSelector />
          <div>
            <a href="#instagram">
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a>
            <a href="#twitter">
              <FontAwesomeIcon icon={faTwitter} size="lg" />
            </a>
            <a href="#linkedin">
              <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
            </a>
          </div>
        </Row>
      </Topbar>
      <NavBar variant="light" expand="lg" sticky={isSticky} ref={headerRef}>
        <NavBar.Brand href="/" className="h-100">
          <img
            src={logo}
            className="d-inline-block align-top"
            alt="COPAC logo"
          />
        </NavBar.Brand>
        <NavBar.Toggle aria-controls="basic-NavBar-nav" />
        <NavBar.Collapse className="justify-content-end">
          <Nav>
            <NavLink href="/" active>
              <Text content="home" />
            </NavLink>
            <Dropdown className="dropdown">
              <Link
                className="dropdown-toggle nav-link"
                to="/about"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Text content="aboutCOPAC" />
              </Link>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <Link className="dropdown-item" to="/about">
                  <Text content="history" />
                </Link>
                <Link className="dropdown-item" to="/about/statutes">
                  <Text content="statutes" />
                </Link>
                <Link className="dropdown-item" to="/about/board-of-directors">
                  <Text content="boardOfDirectors" />
                </Link>
                <Link className="dropdown-item" to="/about/committees">
                  <Text content="committees" />
                </Link>
                {/* <Link className="dropdown-item" to="#"><Text content="minutes" /></Link> */}
                <Link className="dropdown-item" to="/about/documents">
                  <Text content="documents" />
                </Link>
                <Link
                  className="dropdown-item"
                  to="/about/national-federations"
                >
                  <Text content="nationalFederations" />
                </Link>
              </div>
            </Dropdown>
            <Dropdown className="dropdown">
              <Link
                className="dropdown-toggle nav-link"
                to="/disciplines"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Text content="disciplines" />
              </Link>

              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {disciplines.map((discipline) => (
                  <Link
                    key={discipline._id}
                    className="dropdown-item"
                    to={`/disciplines/${discipline._id}`}
                  >
                    {/* <Text content="canoeSprint" /> */}
                    {userLanguage === 'es'
                      ? discipline.spanish.title
                      : discipline.english.title}
                  </Link>
                ))}
              </div>
              {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoeSprint" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoeSlalom" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="paracanoe" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoePolo" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoeMarathon" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoeWildwater" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoeFreestyle" /></Link>
                <Link className="dropdown-item" to="/disciplines/canoe-sprint"><Text content="canoeOceanRacing" /></Link>
              </div> */}
            </Dropdown>
            <NavLink href="/events">
              <Text content="events" />
            </NavLink>
            <NavLink href="/news">
              <Text content="news" />
            </NavLink>
            <NavLink href="/contact">
              <Text content="contact" />
            </NavLink>
            {/* <Dropdown className="dropdown">
              <Link className="dropdown-toggle nav-link" to="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <Text content="tokyo2020" />
              </Link>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <Link className="dropdown-item" to="#"><Text content="canoeSprint" /></Link>
                <Link className="dropdown-item" to="#"><Text content="canoeSlalom" /></Link>
                <Link className="dropdown-item" to="#"><Text content="paracanoe" /></Link>
              </div>
            </Dropdown> */}
          </Nav>
        </NavBar.Collapse>
      </NavBar>
    </>
  );
}

export default Header;
