import React from 'react';

// - react-bootstrap
import {
  Row,
} from 'react-bootstrap';

// - Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

// - styles
import { colors } from 'assets/styles/colors';
import { Title, IconHolder } from '../styles/contactInfoStyles';

function ContactInfo() {
  return (
    <>
      <Row style={{ paddingTop: 80 }}>
        <IconHolder>
          <FontAwesomeIcon icon={faMapMarkerAlt} size="4x" color={colors.yellow} />
        </IconHolder>
        <div>
          <Title>Address</Title>
          <p>Lorem ipsum dolor sit amet</p>
        </div>
      </Row>
      <Row>
        <IconHolder>
          <FontAwesomeIcon icon={faPhoneAlt} size="4x" color={colors.yellow} />
        </IconHolder>
        <div>
          <Title>Phone</Title>
          <p>Lorem ipsum dolor sit amet</p>
        </div>
      </Row>
      <Row>
        <IconHolder>
          <FontAwesomeIcon icon={faEnvelope} size="4x" color={colors.yellow} />
        </IconHolder>
        <div>
          <Title>Email</Title>
          <p>Lorem ipsum dolor sit amet</p>
        </div>
      </Row>
    </>
  );
}

export default ContactInfo;
