import styled from 'styled-components';

import scrolltoparrow from 'assets/img/scroll-top-arrow.png';

import { colors } from 'assets/styles/colors';

export const FooterContainer = styled.div`
  background-color: #101010;
  padding: 80px 0;
`;

export const Copyright = styled.div`
  margin: 0;
  text-align: center;
  background-color: #1a1a1a;
  padding: 30px 0 30px 0;
`;

export const ScrollUpButton = styled.a`
  z-index: 999;
  width: 40px;
  height: 40px;
  opacity: 1;
  position: fixed;
  bottom: 22px;
  right: 20px;
  display: inline;
  cursor: pointer;
  text-indent: -9999px;
  background: ${colors.yellow} url('${scrolltoparrow}') no-repeat 15px 16px;
  z-index: 999;
`;

export const FooterLogo = styled.div`
  margin-bottom: 17px;

  & img {
    margin-top: -23px;
  }
`;

export const FooterTitle = styled.h4`
  font-size: 22px;
  line-height: 25px;
  font-style: normal;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 7px;
  color: #fff;
  float: left;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
`;

export const TitleUnderline = styled.div`
  width: 10%;
  height: 3px;
  margin-right: 95%;
  color: #fff;
  float: left;
  display: block;
  margin-bottom: 40px;
  background-color: #fff;
`;
export const WrapperMargin = styled.div`
  margin-bottom: 30px;
`;

// ============== Contact ==============
export const ContactInfo = styled.ul`
  padding: 0px;
  margin: 0px;

  & > li {
    list-style: none;
    padding: 0px 0px 10px 0px;
    margin: 0 0 10px 0;
    display: block;
    color: #727272;
    line-height: 23px;
    border-bottom: 1px solid #2e2e2e;
  }

  & > li:last-child {
    border-bottom: none;
  }

  & > li > svg {
    margin-right: 15px;
  }
`;

// ============== Recent News ==============
export const NewsImage = styled.div`
  float: left;
  margin: 0;
  padding: 0 20px 0 0;
  transition: all 0.3s ease-out 0s;

  & > img {
    width: 80px;
  }
`;

export const NewsText = styled.div`
  display: block;
  padding: 0px 0px 0px 99px;
  margin: 0px;
  text-align: left;
`;

export const NewsTitle = styled.h5`
  font-size: 18px;
  line-height: 23px;
  font-style: normal;
  margin-bottom: 7px;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;

  & > a {
    color: #fff;
  }
`;

export const NewsPostInfo = styled.div`
  padding: 0px;
  & > span {
    font-size: 13px;
    color: #494747;
    margin-right: 5px;
  }
`;

export const DividerLine = styled.div`
  float: left;
  width: 100%;
  margin: 20px 0;
  border-bottom: 1px solid #444444;
`;

// ============== Quick Links ==============
export const LinkList = styled.ul`
  float: left;
  padding: 0;
  width: 100%;
`;

export const LinkItem = styled.li`
  list-style: none;
  float: left;
  padding: 8px 0;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:first-child {
    padding-top: 0px;
  }

  &:last-child {
    border-bottom: none;
  }

  & a {
    color: #727272;

    &:hover {
      color: #fff;
    }
  }

  /* Ícone FontAwesome */
  & svg {
    margin-right: 15px;
  }

`;

// ============== Instagram ==============
export const ThumbList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

export const Thumb = styled.li`
  list-style: none;
  float: left;
  margin: 0 12px 12px 0;
  padding: 0;
`;
