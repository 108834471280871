import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

// - Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

//import disciplines from 'assets/disciplines';

// - styles
import { Nav, NavItem } from './styles';

// - tradução
import { LanguageContext } from 'containers/Language';

const origin = process.env.REACT_APP_BASE_URL;

function DisciplineNav({ activeItem }) {
  const spacingIcon = { margin: '0 10px 0 0' };

  const [disciplines, setDisciplines] = useState([]);

  const { userLanguage } = useContext(LanguageContext);

  useEffect(() => {
    fetch(`${origin}/api/disciplines`)
      .then(async (response) => {
        const json = await response.json();
        setDisciplines(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []);

  return (
    <Nav>
      <NavItem>
        <Link
          className={activeItem === 'All Disciplines' ? 'active' : ''}
          to="/disciplines"
        >
          <FontAwesomeIcon icon={faAngleRight} style={spacingIcon} />
          {/* All Disciplines */}
          {userLanguage === 'es' ? 'Todas las disciplinas' : 'All Disciplines'}
        </Link>
      </NavItem>
      {disciplines.map((discipline, index) => (
        <NavItem key={discipline._id}>
          {/* <Link className={activeItem === discipline.english.title ? 'active' : ''} to={`/disciplines/${discipline._id}`}> */}
          <Link
            className={activeItem === discipline._id ? 'active' : ''}
            to={`/disciplines/${discipline._id}`}
          >
            <FontAwesomeIcon icon={faAngleRight} style={spacingIcon} />
            {userLanguage === 'es'
              ? discipline.spanish.title
              : discipline.english.title}
          </Link>
        </NavItem>
      ))}
    </Nav>
  );

  /* return (
    <Nav>
      <NavItem>
        <Link className={activeItem === 'All Disciplines' ? 'active' : ''} to="/disciplines">
          <FontAwesomeIcon icon={faAngleRight} style={spacingIcon} />
          All Disciplines
        </Link>
      </NavItem>
      {disciplines.map((item, index) => (
        <NavItem key={item}>
          <Link className={activeItem === item.name ? 'active' : ''} to={item.url}>
            <FontAwesomeIcon icon={faAngleRight} style={spacingIcon} />
            {item.name}
          </Link>
        </NavItem>
      ))}

    </Nav>
  ); */
}

export default DisciplineNav;
