import styled, { css } from 'styled-components';

import { colors } from 'assets/styles/colors';

export const Card = styled.div`
  padding: 50px 30px;
  background: #f9f9f9;
  border-top: 5px solid ${colors.yellow};
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.05), 0 6px 30px 5px rgba(0, 0, 0, 0.1), 0 8px 10px -5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  & h2 {
    color: #666;
    font-weight: bold;
    text-align: center;
    letter-spacing: 2px;
  }
`;

const FormField = css`
  width: 100%;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  color: #0c2850;
  background-color: #ffffff;
  height: 48px;
  line-height: 48px;
  border: 1px solid #e4e4e4;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  ${FormField}
`;

export const Label = styled.label`
  color: #888;
  font-size: 18px;
  margin-bottom: 5px;
`;

export const TextArea = styled.textarea`
  ${FormField}
  min-height: 100px;
`;

export const Button = styled.button`
  width: 100%;
  background-color: ${colors.yellow};
  font-size: 18px;
  letter-spacing: 1px;
  color: white;
  padding: 15px 30px;
  border: none;

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: #242424;
  }
`;
