import { createGlobalStyle } from 'styled-components';

// - Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

import { Reset } from 'assets/styles/reset';

import { colors } from 'assets/styles/colors';

const GlobalStyle = createGlobalStyle`
  ${Reset}

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
	  color: #727272;
    line-height: 23px;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }

  a, button {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }

  a:hover {
    text-decoration: none;
  }

  /* Headings
  ------------------------------ */

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    color: #272727;
  }

  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit;
  }

  h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 300;
  }

  h2 {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 300;
  }

  h3 {
    font-size: 27px;
    line-height: 30px;
    margin-bottom: 18px;
    font-style: normal;
  }

  h4 {
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 18px;
    font-style: normal;
  }

  h5 {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 16px;
    font-style: normal;
  }

  h6 {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 14px;
    font-style: normal;
  }

  .h1, .h2, .h3, h1, h2, h3, h4 {
    margin-top: 0;
  }

  p {
    margin: 0 0 10px;
  }

  .container,
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .container{
    width: 94%;
    max-width: unset;
  }

  @media (min-width: 768px) {
    .container {
      width: 750px;
      max-width: 750px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
      max-width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 1170px;
      width: 1170px;
    }
  }

  .dropdown:hover>.dropdown-menu {
    display: block;
  }

  .navbar-toggler {
    border: none;
  }

  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .dropdown-item.active, .dropdown-item:active {

    background-color: transparent;
  }

  .col-xs-5ths,
  .col-sm-5ths,
  .col-md-5ths,
  .col-lg-5ths {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      flex: unset;
  }

  .col-xs-5ths {
      width: 20%;
      float: left;
  }

  @media (min-width: 768px) {
      .col-sm-5ths {
          width: 20%;
          float: left;
      }
  }

  @media (min-width: 992px) {
      .col-md-5ths {
          width: 20%;
          float: left;
      }
  }

  @media (min-width: 1200px) {
      .col-lg-5ths {
          width: 20%;
          float: left;
      }
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: white;
  }

  .table-striped td:nth-of-type(even) {
    background-color: rgba(0,0,0,.03);
  }

  .table-bordered th:nth-of-type(odd) {
    background-color: #e6ac00;
    color: #fff;
  }

  .table-bordered th:nth-of-type(even) {
    background-color: ${colors.yellow};
    color: #fff;
  }

  .table-bordered th,.table-bordered td, .table-bordered, .table-bordered thead td, .table-bordered thead th {
    border: none;
  }

  .table-bordered tbody  {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  .table-bordered th, .table-bordered td {
    padding-left: 30px;
  }

  .table-bordered td {
    border: none;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  .page-link {
    color: #333;
  }

  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: ${colors.yellow};
    border-color: ${colors.yellow};
  }

  .page-link:hover {
    color: ${colors.yellow};
    background-color: #f5f5f5;
  }

  .page-item.disabled .page-link {
    background-color: #f3f3f3;
  }
`;

export default GlobalStyle;
