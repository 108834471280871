import styled from 'styled-components';

export const ListItem = styled.li`
  padding: 0px;
  margin: 0 0 13px 0;
  display: block;
  line-height: 23px;

  & svg {
    margin: 0 15px 0 0;
  }

  & .internal-list {
    padding-left: 40px;
  }
`;
