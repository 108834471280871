import styled from 'styled-components';

import { colors } from 'assets/styles/colors';

import {
  Tabs
} from 'react-bootstrap';

export const TabsOutlineNone = styled(Tabs)`
  a {
    font-size: 16px;
    font-weight: 600;

    &:focus {
      outline: 0;
    }
  }

`;

export const Title = styled.a`
  font-size: 16px;
  font-weight: 600;

  &:focus {
    outline: 0;
  }

  @media (max-width: 768px) {
    display: inline-block;
    margin-top: 8px;
  }
`;

export const PostInfo = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;

  & span {
    font-size: 14px;
    color: #727272;
    margin-right: 15px;
    & svg {
      margin-right: 5px;
    }
  }
`;

export const NewsListContainer = styled.div`
  /* margin: 10px 0;
  padding: 15px 0; */

  display: grid;
  gap: 32px;
`;

export const DocumentContainer = styled.div`
  /* padding: 32px 20px; */
  padding: 0 20px;

  display: flex;
  align-items: center;

  & + & {
    //border-top: 1px solid black;
    border-top: 1px solid rgba(0, 0, 0,.125);
  }
`;

export const ContentContainer = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
  flex: 1;

  h5 {
    margin: 8px 0 0 16px;
  }

  img {
    max-height: 60px;
  }
`;

export const NewsButton = styled.a`
  color: #fff;
  background-color: ${colors.yellow};
  border-radius: 2px;
  padding: 10px 36px;
  box-shadow: none;
  align-self: flex-start;

  &:hover {
    color: #fff;
    background-color: #242424;
  }
`;

export const NewsButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
