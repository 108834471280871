import React, { useState } from 'react';
// import ReCAPTCHA from "react-google-recaptcha";

// - styles
import {
  Card, Label, Input, TextArea, Button,
} from '../styles/contactFormStyles';

function ContactForm() {
  const [values, setValues] = useState({});
  // const [disabled, setDisabled] = useState(true);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  // const onChangeCaptcha = value => {
  //   setDisabled(false);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(values);
  };

  return (
    <Card>
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <Label htmlFor="name">Name</Label>
          <Input
            type="text"
            value={values.name}
            id="name"
            name="name"
            onChange={handleChange}
            placeholder="Type your name"
          />
        </div>
        <div>
          <Label htmlFor="email">E-mail</Label>
          <Input
            type="email"
            value={values.email}
            id="email"
            name="email"
            onChange={handleChange}
            placeholder="Type your e-mail"
          />
        </div>
        <div>
          <Label htmlFor="subject">Subject</Label>
          <Input
            type="text"
            value={values.subject}
            id="subject"
            name="subject"
            onChange={handleChange}
            placeholder="Type the subject"
          />
        </div>
        <div>
          <Label htmlFor="message">Message</Label>
          <TextArea
            value={values.message}
            id="message"
            name="message"
            onChange={handleChange}
            placeholder="Type the message"
          />
        </div>
        {/* <div>
          <ReCAPTCHA
            sitekey=""
            onChange={onChangeCaptcha}
          />
        </div> */}
        <div>
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </Card>
  );
}

export default ContactForm;
