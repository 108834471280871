import styled from 'styled-components';

export const Nav = styled.ul`
  list-style-type: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
  float: left;
`;

export const NavItem = styled.li`
  width: 100%;
  padding: 0px;
  margin: 0 0 2px 0;
  float: left;

  & a {
    width: 100%;
    padding: 15px 25px;
    margin: 0px;
    font-weight: normal;
    float: left;
    background-color: #f6f6f6;
    outline: medium none !important;
    color: #727272;

    &.active {
      color: #fff;
      background-color: #ffc000;
    }

    &:hover {
      color: #fff;
      background-color: #ffc000;
    }
  }

`;
