import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from 'assets/styles/colors';

export const NewsTitle = styled.h5`
  margin-bottom: 3px;
  text-transform: uppercase;
`;

export const ImageHolder = styled.div`
  margin-bottom: 37px;
`;

export const Divider = styled.div`
  margin: 20px 0;
  border-bottom: 1px solid;
`;

export const Button = styled(Link)`
  color: #fff;
  background-color: ${colors.yellow};
  border-radius: 2px;
  padding: 10px 36px;
  box-shadow: none;
  align-self: flex-start;

  &:hover {
    color: #fff;
    background-color: #242424;
  }
`;
