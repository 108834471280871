import styled, { css } from 'styled-components';

import { Navbar, Nav } from 'react-bootstrap';

import { colors } from 'assets/styles/colors';

export const Topbar = styled.div`
  background-color: #006CB1;
  color: #fff;
  padding: 15px;
  float: left;
  width: 100%;
  margin: 0px;

  & a {
    color: #fff;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

`;

export const NavBar = styled(Navbar)`

  & .navbar-nav {
    overflow-y: scroll;
    height: 400px;

    @media (min-width: 992px) {
      overflow-y: unset;
      height: unset;
    }
  }

  height: 80px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
  z-index: 999;

  & .navbar-collapse {
    background-color: #fff;

    margin-left: -15px;

    & .navbar-nav {
      width: 100vw;

      @media (min-width: 992px){
        width: auto;
      }
    }
  }

  ${(props) => (props.sticky === true ? `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    ` : `
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    `)
}

  box-shadow: 1px 1px 5px rgba(0,0,0, 0.15);

`;

const NavItems = css`
  color: #777 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  text-transform: uppercase;

  &:hover {
    color: ${colors.yellow} !important;
  }
`;

export const NavLink = styled(Nav.Link)`
  ${NavItems}
`;

export const Dropdown = styled.div`
  & a {
    ${NavItems}
  }

  & .dropdown-menu {
    display: block;
    border: none;
    padding: .5rem 30px;


    @media (min-width: 992px) {
      display: none;
      border: 1px solid rgba(0,0,0,.15);
      padding: .5rem 0;
    }

    & a {
      display: block;
      color: #727272;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857;
      padding: 8px 30px;
      white-space: nowrap;
      text-transform: unset;

    }
  }
`;

export const Logo = styled.img`
  height: 100%;
  width: auto;
`;
