import styled from 'styled-components';

export const Title = styled.h3`
`;

export const Status = styled.h2`
  font-weight: 600;
  color: #bbb;
  font-size: 8rem;
  line-height: 1.2;
  letter-spacing: .5rem;
`;
