import styled from 'styled-components';

import { colors } from 'assets/styles/colors';

export const TextBox = styled.div`
  padding-top: 50px;
`;

export const Title = styled.h3`
  font-family: "Raleway", sans-serif;
`;

export const PostInfo = styled.div`
  & span {
    font-size: 13px;
    color: #b9b9b9;
    margin-right: 15px;
    & svg {
      margin-right: 5px;
    }
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid #e4e4e4;
`;

export const FilterButton = styled.button`
  margin-right:15px;
  margin-bottom: 8px;
  border: none;
  padding: 5px 15px;
  color: #fff;
  background-color: ${(props) => (props.active ? colors.yellow : '#aaa')};

  &:focus {
    outline: 0;
  }
`;
